import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FieldDTO, GuidedExperienceDTO, WindowMessageEventName } from '@next/shared/common';
import { ViewerService } from '../state/viewer.service';
import { FormGroup } from '@angular/forms';
import { ErrorRibbonService } from '../state/error-ribbon.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'next-viewer-footer',
  templateUrl: './viewer-footer.component.html',
  styleUrls: ['./viewer-footer.component.css']
})

export class ViewerFooterComponent {

  @Input() submitDisabled: boolean;
  @Input() allowSave = true; // Ex: Do not allow save on Anonymous Forms
  @Input() form: FormGroup;
  @Input() selectedPage: number;
  @Input() experience: GuidedExperienceDTO;
  @Output() nextPageEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() prevPageEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() submitEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() saveEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancelEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() needsValidationEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(private viewerSvc: ViewerService, private errorSvc: ErrorRibbonService, private translateSvc: TranslateService) {}

  isPageComplete(includeRequiredValidation: boolean): boolean {
    const pageFields: FieldDTO[] = this.experience.pages[this.selectedPage].fields;
    return this.viewerSvc.validateFields(pageFields, this.form, includeRequiredValidation);
  }

  prevPage() {
    this.prevPageEvent.emit(this.selectedPage);
  }

  cancelForm() {
    window.parent.postMessage({eventName: WindowMessageEventName.ExperienceCanceled},'*');
    this.cancelEvent.emit()
  }

  nextPage() {
    this.needsValidationEvent.emit();
    const goNextPage: boolean = this.isPageComplete(true);
    goNextPage ? this.nextPageEvent.emit(this.selectedPage) : this.errorSvc.showErrorMessage(this.translateSvc.instant('WARNING_RIBBON.INVALID_MSG'));
  }

  submit() {
    this.needsValidationEvent.emit();
    const doSubmit: boolean = this.isPageComplete(true);
    doSubmit ? this.submitEvent.emit() : this.errorSvc.showErrorMessage(this.translateSvc.instant('WARNING_RIBBON.INVALID_MSG'));
  }

  save() {
    const doSave: boolean = this.isPageComplete(false);
    doSave ? this.saveEvent.emit() : this.errorSvc.showErrorMessage(this.translateSvc.instant('WARNING_RIBBON.INVALID_MSG'));
  }

  isLastPage(experience: GuidedExperienceDTO): boolean {
    return this.selectedPage === experience.pages.length - 1;
  }

  isFirstPage(): boolean {
    return this.selectedPage === 0;
  }
}
