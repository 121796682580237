import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldDTO, GuidedExperienceDTO } from '@next/shared/common';
import { FormGroup } from '@angular/forms';
import { ViewerService } from '../state/viewer.service';
import { Observable } from 'rxjs';
import { NextImageService } from '@next/shared/next-services';

@Component({
  selector: 'next-viewer-main',
  templateUrl: './viewer-main.component.html',
  styleUrls: ['./viewer-main.component.css']
})

export class ViewerMainComponent implements OnInit {

  @Input() form: FormGroup;                   // Viewer Reactive Web-Form object
  @Input() formId: string;                    // Form ID
  @Input() experience: GuidedExperienceDTO;   // GuidedExperienceDTO Object
  @Input() initialState: any;                 // Initial values for form (post prefill, calculation, form-data)
  @Input() attachments: any[];                // Attachment file array
  @Input() needsValidation: boolean;

  @Output() valueChanged: EventEmitter<FieldDTO> = new EventEmitter<FieldDTO>();

  secureImageUrl: string;
  selectedPage: Observable<number>;

  constructor(private viewerSvc: ViewerService, private nextImageSvc: NextImageService) { }

  ngOnInit() {
    this.selectedPage = this.viewerSvc.getSelectedPage();
    this.setLogo();
  }

  emitValueChanged(field: FieldDTO) {
    this.valueChanged.emit(field);
  }

  setLogo() {
    this.nextImageSvc.getImages().subscribe(images => {
      const image = images.find(img=> img.url === this.experience.logoUrl);
        if (image) {
          this.secureImageUrl = image.secureUrl;
        }
    });
  }

  getSecureLogoUrl() {
    return this.secureImageUrl;
  }
}
