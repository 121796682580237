import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { ViewerService } from '../state/viewer.service';
import {
  NextAdminService,
  NextSubmissionService,
  SignatureService,
} from '@next/shared/next-services';
import {
  FieldDTO,
  FormSubmission,
  GuidedExperienceDTO,
  SubmissionType,
  WindowMessageEventName,
  SignatureType,
  SubmissionMetadata,
  GxProcessing,
  TokenService,
} from '@next/shared/common';
import * as platform from 'platform';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { HttpEventType } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'next-pdf-viewer',
  templateUrl: './viewer-pdf.component.html',
  styleUrls: ['./viewer-pdf.component.css'],
  /* This will cause a viewer service to instantiate every time this Component is created.
   * We do this to reset the internal state fresh each time you navigate to the tool */
  providers: [ViewerService]
})

export class ViewerPdfComponent implements OnInit, OnDestroy {
  @ViewChild('iframe', {static: true}) iframe: ElementRef;

  @Output() pdfViewerEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() isEmbedded = false;
  @Input() isPatientView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  @Input() isFormValidForCurrentView = true;
  @Input() isFormValidForAll = true;

  formId: string;
  taskId: string;

  formData: any;
  experience: GuidedExperienceDTO;
  submission: FormSubmission;
  fields: FieldDTO[];
  prefill: any = { };
  loading = true;
  userId = ''
  RequiredClinicianSignatures: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(
    private adminSvc: NextAdminService,
    private viewerSvc: ViewerService,
    private submissionSvc: NextSubmissionService,
    private zone: NgZone,
    private translateSvc: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private gxProcessing: GxProcessing,
    private signatureService: SignatureService,
    private tokenService: TokenService
  ) { }


  ngOnInit() {
    // Bindings for direct iFrame access
    // Form Data
    (<any>window).loadAnnotationState = this.loadAnnotationState.bind(this);

    // Guided Experience Data
    (<any>window).loadGXData = this.loadGXData.bind(this);


    // The Form Id may not be linked with an existing submission.
    // When this occurs the client is telling us to use this id for a new submission.
    const urlParams = new URLSearchParams(window.location.search);
    this.formId = this.viewerSvc.formId = urlParams.get('formId');
    this.taskId = urlParams.get('taskId');

    if (this.isEmbedded) {
      (<any>window).isEmbedded = this.isEmbedded;
      this.isPatientView.subscribe((result) => {
        (<any>window).isPatientView = result;
      });

      this.RequiredClinicianSignatures.subscribe((result) => {
        (<any>window).RequiredClinicianSignatures = result;
      });

      this.activatedRoute.data.subscribe(data => {
        if (data.task) {
          this.formId = this.viewerSvc.formId = data.task.formid;
          this.taskId = data.task.id;
        }
      });
    }

    this.viewerSvc.getExperience().subscribe(result => {

      if (result) {
        this.submission = result.submission;
        this.prefill = result.prefill;

        // Setup initial data
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const componentRef = this;

        this.gxProcessing.getViewerDataAndConfig(
          result.experience,
          result.submission?.data || { },
          result.prefill || '')
          .then(
            function (results) {

              componentRef.experience = results.experience
              componentRef.formData = results.data;

              // Used for processing calculations
              componentRef.fields = componentRef.gxProcessing.getAllFields(componentRef.experience);

              if (componentRef.isEmbedded && !componentRef.isPatientView.value) {
                componentRef.signatureService.getAssignedSignatures(componentRef.experience.vid, componentRef.formId).subscribe((assignedSignatures) => {
                  const sigs: string[] = [];

                  for (let i=0; i < componentRef.fields.length; i++) {
                    if (componentRef.fields[i]["signatureFor"]
                      && componentRef.fields[i]["signatureFor"] === 'staff'
                      && componentRef.fields[i]["required"]
                      && componentRef.isCurrentUserRequiredToSign(componentRef.fields[i]["name"], assignedSignatures)) {
                        sigs.push(componentRef.fields[i]["name"]);
                    }
                  }

                  componentRef.RequiredClinicianSignatures.next(sigs);
                  componentRef.loadPdf();
                });

              } else {
                componentRef.loadPdf();
              }
        });
      }
    });
    this.viewerSvc.init().subscribe();
  }

  isCurrentUserRequiredToSign(fieldName, assignedSignatures) {
    const assignedSignature = assignedSignatures.find(a => a.fieldname === fieldName);
    if (assignedSignature) {
      return assignedSignature.assigntoid === this.tokenService.getCurrentUserId() ||
      this.tokenService.isIdInCurrentUsersGroups(assignedSignature.assigntoid)
    }

    return false;
  }

  ngOnDestroy() {
    this.formData = undefined;
  }

  /**
   * Triggered by the Viewer's webviewerloaded event.
   */
  public async onViewerLoad(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const componentRef = this;
    const viewerApp = this.iframe.nativeElement.contentWindow?.PDFViewerApplication;

    // Wait for initialization to complete so that we can access the Event Bus
    viewerApp?.initializedPromise.then(function() {
      componentRef.zone.run(() => {
        componentRef.loading = false;
      });

      // Hook Event Listeners
      if (componentRef.isEmbedded) {
        let pagesRendered = [];

        viewerApp.eventBus?.on('pagerendered', (e) => {
          componentRef.zone.run(() => {
            pagesRendered.push(e);
            if (pagesRendered.length) {
              viewerApp.eventBus.dispatch('firstpage');
              componentRef.pdfViewerEmitter.emit({
                eventName: WindowMessageEventName.RenderedPDF,
                viewerApp: viewerApp
              })
              pagesRendered = [];
            }
          });
        });

        viewerApp.eventBus?.on('fieldchange', (e) => {
          componentRef.zone.run(() => {
              componentRef.pdfViewerEmitter.emit({
                eventName: WindowMessageEventName.FieldChanged,
                experience: componentRef.experience,
                formData: componentRef.formData
              });
          });
        });

        viewerApp.eventBus.on('signature-signed', (evt) => {
          componentRef.pdfViewerEmitter.emit({
            eventName: WindowMessageEventName.FieldChanged,
            experience: componentRef.experience,
            formData: componentRef.formData
          });
        });

        viewerApp.eventBus.on('sign', (e) => {
          componentRef.zone.run(() => {
            const signatureDTO = e.data.GXField || { };
            componentRef.pdfViewerEmitter.emit({
              eventName: WindowMessageEventName.Signature,
              signatureProperties: {
                signatureFor: signatureDTO.signatureFor || 'patient',
                signatureType: signatureDTO.signatureType || 'drawn',
                signatureName: signatureDTO.name || e.data.fieldName
              }
            });
          })
        });
      }
      viewerApp.eventBus.on('submitform', componentRef.submit.bind(componentRef, SubmissionType.Submitted));
      viewerApp.eventBus.on('fieldchanged', componentRef.onFieldChange.bind(componentRef));
      viewerApp.eventBus.on('staff-signature', componentRef.onStaffSignature.bind(componentRef));
      viewerApp.eventBus.on('validationchanged', componentRef.onValidationChanged.bind(componentRef));
      viewerApp.eventBus.on('allrequiredfieldschanged', componentRef.onAllRequiredFieldsChanged.bind(componentRef));
    });
  }

  /**
   * Loads the HTML Viewer in our iframe, specifying the location of the PDF to load
   */
  private loadPdf() {
    this.iframe.nativeElement.src = `assets/pdfjs/web/viewer.html?file=${encodeURIComponent(this.experience.pdftemplate.url)}`;
  }

  /**
   * Sets a global annotation variable that the Viewer will access for loading the initial state.
   * This is called directly by the Viewer and will block until complete.
   * This required a modification to the viewer.
   */
  public loadAnnotationState(): any[] {

    // Transform our current form data state into something that PDFJS can process
    let data = [];

    // If the viewerSvc's form object is available, that means the PDF Viewer was loaded as part of an Experience flow.
    // All of the data captured on our web-form pages will be what we process.  This already takes into account prefill, etc.
    if (this.viewerSvc.form) {
      // We don't really support this at this time
    }
    // If there was a prior submission, prefill will have already have been triggered.  Simply load this state.
    // The PDF Viewer was launched into directly, probably as part of a multi-step workflow.
    else if (this.formData) {
      data = this.formData;
    }
    // Else, You have launched directly into the PDF Viewer w/o a prior submission and w/o any prefill data.
    return data;
  }

  /**
   * Sets a global annotation variable that the Viewer will access for loading the initial state.
   * This is called directly by the Viewer and will block until complete.
   * This required a modification to the viewer.
   */
  public loadGXData(): any[] {
    return this.gxProcessing.getAllFields(this.experience);
  }

  /**
   * Triggered from the Viewer toolbar, this will submit the form to our API
   */
  public async submit(submissionType: SubmissionType, postMessage: boolean = true): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const componentRef = this;

    const processedData: any = await this.gxProcessing.processFields(this.experience, this.formId, 0, this.formData, this.submissionSvc);
    const submitData: any = await this.viewerSvc.processCalculations(this.experience, submissionType, this.submission?.data || { }, processedData,this.prefill || '');

    // Append prefill data to the form data if it exists
    if (this.prefill && Object.keys(this.prefill).length) {
      Object.assign(submitData, {
        _prefill_c4af0d49_e948_4737_8c12_8d64511faeec: this.prefill
      });
    }

    const payload: FormSubmission = {
      id: componentRef.submission?.id || componentRef.formId || null,
      experienceversionid: componentRef.experience.vid,
      submissiontype: submissionType,
      updatedby: '',
      fileid: null,
      data: submitData,
      metadata: {
        client: platform,
        page: 0
      } as SubmissionMetadata,
      taskId: componentRef.taskId,
      lastupdated: componentRef.submission?.lastupdated || null
    };


    // If there is an existing submission, update it
    // Else, create a new form.  A formId may or may not have been provided.
    const upsert = componentRef.submission
      ? componentRef.submissionSvc.update(payload)
      : componentRef.submissionSvc.create(payload);

    upsert.subscribe(result => {
      if (result.type === HttpEventType.Response) {
        componentRef.submission = result.body; // Store last submission
        this.location.go(
          this.router.createUrlTree([], {
            relativeTo: this.activatedRoute,
            queryParams: { formId: result.body.id },
            queryParamsHandling: 'merge' }
          ).toString());

        if (componentRef.isEmbedded && componentRef.pdfViewerEmitter) {
          componentRef.pdfViewerEmitter.emit({
            eventName: (submissionType === SubmissionType.Saved)
              ? WindowMessageEventName.ExperienceSave
              : WindowMessageEventName.ExperienceSubmit,
            formId: componentRef.submission.id,
            taskId: componentRef.taskId
          });
        }
        if (postMessage && window.parent) {
          window.parent.postMessage({
            eventName: (submissionType === SubmissionType.Saved)
              ? WindowMessageEventName.ExperienceSave
              : WindowMessageEventName.ExperienceSubmit,
            formId: componentRef.submission.id,
            taskId: componentRef.taskId
          },'*');
        }
        if (postMessage && window.opener) {
          window.opener.postMessage({
            eventName: (submissionType === SubmissionType.Saved)
              ? WindowMessageEventName.ExperienceSave
              : WindowMessageEventName.ExperienceSubmit,
            formId: componentRef.submission.id,
            taskId: componentRef.taskId
          }, '*');
        }
      }
    },
    error => {
        if (componentRef.isEmbedded && error.status === 409) {
            componentRef.pdfViewerEmitter.emit({ eventName: WindowMessageEventName.SubmitError, formId: payload.id });
        }
    });
  }

  private onFieldChange(evt) {
    // The input element that triggered the change
    const viewerDocument = this.iframe.nativeElement.contentWindow.document;
    const inputEls = viewerDocument.getElementsByName(evt.source.data.fieldName);

    const inputEl = inputEls.length === 1 ?
      inputEls[0] : // The element that was updated
      [].find.call(inputEls, el => el.checked === true); // Get the one that is checked.  Should work on ES5

    // The current state of our Form Data - This will be updated
    const state = this.formData;
    this.updateStateFromElement(state, inputEl);

    // The field object that contains an optional Calculation function
    // This may be null if a field exists in the PDF that does not in the Exp.
    const field = this.fields.find(obj => obj.name === evt.source.data.fieldName );

    // If the field exists, we want to run its calc function and update all affected fields in the Viewer
    if (field) {
      this.runFieldCalculation(field, state);
    }

    if (this.isEmbedded) {
      this.pdfViewerEmitter.emit({
        eventName: WindowMessageEventName.FieldChanged,
        experience: this.experience,
        formData: this.formData,
        submission: this.submission
      });
    }
  }

  /**
   * The viewer requests for staff signature,
   * if valid access code, fill the signature
   * with the saved signature for this staff.
   * Dispatch the filled signature back to
   * the viewer.
   * @param event
   * @private
   */
  public async onStaffSignature(event) {
    let value = null;
    try {
      if (event.applySignature) {
        value = {
          Type: event.Type,
          Value: event.Value
        }
      } else {
        const uuid = event.id;
        const signatureType = event.signatureType;

        const serverAccessCode = await this.adminSvc.getPreference(uuid, 'ACCESSCODE').pipe(map(res => res[0].data.ACCESSCODE)).toPromise();
        if (serverAccessCode === event.code) {
          const signatureDefaults = await this.adminSvc.getPreference(uuid, 'DEFAULTSIGNATURES').pipe(map(res => res[0].data)).toPromise();
          switch (signatureType) {
            case SignatureType.Initials:
              value = signatureDefaults.INITIALS;
              break;
            case SignatureType.TypedSignature:
              value = signatureDefaults.TEXT;
              break;
            case SignatureType.DrawnSignature:
              value = signatureDefaults.STROKES;
              break;
          }
        }

        if (value && value.length) {
          const valueProperty = Array.isArray(value) ? { Strokes: value, SignedDate: new Date() } : { Text : value, SignedDate: new Date() };
          const typeProperty = Array.isArray(value) ? 'Signature' : 'TypedSignature';
          value = {
            Type: typeProperty,
            Value: valueProperty
          }
        }
        else {
          const res = await this.translateSvc.get('ERRORS.EVENT_ERROR', { event: event.id || 'null', code: event.code || 'null'  }).toPromise();
          value = { err: res }
        }
      }
    }
    catch (err) {
      value = { err: JSON.stringify(err) };
    }
    finally {
      // Send an event to the PDF Viewer
      // notifying of filled staff signature
      const viewerApp = this.iframe.nativeElement.contentWindow.PDFViewerApplication;
      viewerApp.eventBus.dispatch('signature-signed', value);
    }
  }


  public async onValidationChanged(event) {
    this.isFormValidForCurrentView = event.source.isValid;
  }


  public async onAllRequiredFieldsChanged(event) {
    this.isFormValidForAll = event.source.isEntireFormValid;
  }

  /**
   * Run the optional OnChange calculation for a specific field.
   * This will do three things:
   *    1. Run the calculation resulting in field changes
   *    2. Update our form data state with the new field values
   *    3. Update the Viewer elements with that same information
   *
   * A calculation will NOT trigger another OnChange calculation.
   * @param field
   * @param state
   */
  private runFieldCalculation(field: FieldDTO, state: any) {
    // Run Change Calculation on Field
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const componentRef = this;
    this.viewerSvc.getCalculationData(field.calculations.onChange, this.experience, state, this.prefill).then(function(updatedValues) {
      // Not every field has a calculation, and not every calculation may trigger a field update
      if (updatedValues) {
        const viewerDocument = componentRef.iframe.nativeElement.contentWindow.document;

        // Iterated all fields that were updated as part of this calculation
        for (const key in updatedValues.fields) {
          // eslint-disable-next-line no-prototype-builtins
          if (!updatedValues.fields.hasOwnProperty(key)) continue; // Resolve Lint error

          const updatedField = updatedValues.fields[key];
          state[key] = updatedField;

          // Update the affected field in the PDF Viewer
          const updatedEls = viewerDocument.getElementsByName(key);
          if (!updatedEls.length) continue; // Protect against a bad script field

          // Some elements are grouped, like radio buttons, and need to be processed specially.
          if (updatedEls.length > 1) {

            // Process Radio group - Find the specific input element by value
            const elements: any[] = Array.from(updatedEls);
            const selectRadio = elements.find(obj => obj.type === 'radio' && obj.value === updatedField.Value.Text);

            // If it was found, check it
            if (selectRadio) {
              selectRadio.checked = true;
            }
          }
          else {

            // A regular field - One element that represents the field (unlike Radio).
            const updateEl = updatedEls[0];

            switch (updateEl.type)
            {
              case 'checkbox':
                updateEl.checked = updatedField.Value.Text !== '';
                break;
              default:  // text, select, textarea
                updateEl.value = updatedField.Value.Text;
            } // switch
          }

        } // For all updated fields
        componentRef.experience = componentRef.gxProcessing.getUpdatedConfig(componentRef.experience, updatedValues.configs);

        // Send an event to the PDF Viewer with just the updated configs
        const viewerApp = componentRef.iframe.nativeElement.contentWindow.PDFViewerApplication;
        viewerApp.eventBus.dispatch('updateconfigs', Object.keys(updatedValues.configs).map(function (key) { return updatedValues.configs[key]; }));
      } // If fields have been updated
    });
  }


  /**
   * Update the form data state with form element data found in each annotation layer
   * NOTE: Currently not used, but could be useful in the future.
   * @param state
   */
  private updateStateFromElements(state) {

    // Get all annotation layers that have been rendered
    const viewerDocument = this.iframe.nativeElement.contentWindow.document;
    const annotLayers = viewerDocument.getElementsByClassName('annotationLayer');

    // Iterate through each annotation layer
    for (const annotLayer of annotLayers) {

      // Note: For radio, we are only getting back the specific element that was selected
      const inputEls = annotLayer.querySelectorAll("input[type='text'], input[type='checkbox'], input[type='radio']:checked, select, textarea");
      for (const inputEl of inputEls) {

        this.updateStateFromElement(state, inputEl);
      }
    } // For all annotation layers
  }


  /**
   * Update the form data state with data stored in a specific element
   * @param state
   * @param inputEl
   */
  private updateStateFromElement(state, inputEl) {

    // Get field object that represents the field data.  This includeds Type, Value, etc.
    const fieldValue = state[inputEl.name];
    if (!fieldValue) return; // Field state should be defaulted by pdfjs

    // Update the field value directly from the input element.
    // Different types of elements need to be processed differently.
    switch (inputEl.type)
    {
      case 'checkbox':
        fieldValue.Value.Text = inputEl.checked ? inputEl.value : '';
        break;
      default:  // text, select, textarea, radio
        fieldValue.Value.Text = inputEl.options ? inputEl.options[inputEl.selectedIndex].value : inputEl.value;
    } // switch
  }

  @HostListener('document:webviewerloaded', ['$event'])
  webViewerLoaded(event) {
    if (event.type === 'webviewerloaded') {
      this.onViewerLoad();
    }
  }
}
