import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ViewerService } from '../state/viewer.service';
import { Subject, timer } from 'rxjs';
import {
  Attachment,
  FieldDTO,
  FormSubmission,
  GuidedExperienceDTO,
  GxProcessing,
  SubmissionType,
  ViewerFieldType,
  WindowMessageEventName,
} from '@next/shared/common';
import { FormGroup } from '@angular/forms';
import {
  NextAdminService,
  NextSubmissionService,
} from '@next/shared/next-services';
import { ErrorRibbonService } from '../state/error-ribbon.service';
import { ToastrService } from 'ngx-toastr';
import * as platform from 'platform';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'next-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.css'],
  animations: [
    trigger('fadeInOut',[
      transition(':enter',
        [ style({opacity: 0 }), animate('400ms ease-in-out', style({opacity: '*' }))]),
      transition(':leave',
        [ style({opacity: '*' }), animate('400ms ease-in-out', style({opacity: 0 }))])
    ])
  ],
  /* This will cause a viewer service to instantiate every time this Component is created.
   * We do this to reset the internal state fresh each time you navigate to the tool */
  providers: [ViewerService, ErrorRibbonService],
})

export class ViewerComponent implements OnInit, OnDestroy {
  SubmissionType: typeof SubmissionType = SubmissionType; // allow this enum to be used in the template

  formId: string;
  taskId: string;
  form: FormGroup;
  submission: FormSubmission;
  experience: GuidedExperienceDTO;
  selectedPage: number;
  attachments: Attachment[];
  prefill: any = { };
  initialData: any = { };
  needsValidation: boolean;
  loading = true;
  loadingMessage: string;
  loadingProgress = 0;
  btnDisabled: boolean;
  scrolledToBottom = false;

  time = 0;
  obsCleanup: Subject<void> = new Subject<void>();

  constructor(
    public errorSvc: ErrorRibbonService,
    private viewerSvc: ViewerService,
    private submissionSvc: NextSubmissionService,
    private toastr: ToastrService,
    private translateSvc: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private adminSvc: NextAdminService,
    private location: Location,
    private gxProcessing: GxProcessing,
  ) { }

  async ngOnInit(): Promise<void> {

    // The Form ID may not be linked with an existing submission.
    // When this occurs the client is telling us to use this id for a new submission.
    this.activatedRoute.queryParams.subscribe(params => {
      this.formId = this.viewerSvc.formId = params['formId'];
      this.taskId = this.viewerSvc.taskId = params['taskId'];
    });

    this.viewerSvc.form = this.form = new FormGroup({ });
    this.viewerSvc.getSelectedPage().pipe(
      takeUntil(this.obsCleanup),
    ).subscribe((page: number) => {
      this.selectedPage = page || 0;
    });

    this.viewerSvc.getExperience().pipe(
      takeUntil(this.obsCleanup)
    ).subscribe(async result => {
      if (result) {
        this.submission = result.submission;
        this.attachments = result.attachments;
        this.prefill = result.prefill;
        const results = await this.gxProcessing.getViewerDataAndConfig(result.experience, result.submission?.data || { }, result.prefill || '');

        this.experience = results.experience;
        this.initialData = results.data;
        this.viewerSvc.setupInitialForm(this.initialData, this.experience);

        this.adminSvc.createMetric('StartFormViewer', this.experience);
        this.loading = false;
      }
    });

    this.viewerSvc.init().pipe(
      takeUntil(this.obsCleanup)
    ).subscribe();

    timer(0, 1000).pipe(
      takeUntil(this.obsCleanup)
    ).subscribe(() => {
        this.time++;
    });
  }

  ngOnDestroy(): void {
    this.obsCleanup.next();
    this.obsCleanup.complete();
  }

  async goNextPage(): Promise<void> {
    this.errorSvc.clearMessage();

    if (
      this.viewerSvc.pageViewed(this.selectedPage)
      || this.scrolledToBottom
      || this.isViewingBottom()
    ) {
      // Save the form data if dirty
      if (this.form.dirty) {
        await this.submit(SubmissionType.Saved, this.taskId, false);
      }

      this.resetScroll();
      this.errorSvc.clearMessage();
      this.viewerSvc.nextPage();
      this.needsValidation = false;

      this.createMetric(true);
    }
    else {
      this.showNotScrolledError();
    }
  }

  async goPrevPage(): Promise<void> {
    if (this.form.dirty) {
      await this.submit(SubmissionType.Saved, this.taskId, false);
    }

    this.resetScroll();
    this.errorSvc.clearMessage();
    this.viewerSvc.prevPage();

    this.createMetric(false);
  }

  async submit(submissionType: SubmissionType, taskId: string = this.taskId, postMessage: boolean = true): Promise<void> {
    if (submissionType === SubmissionType.Submitted) {
      if (
        !this.viewerSvc.pageViewed(this.selectedPage)
        && !this.scrolledToBottom
        && !this.isViewingBottom()) {
        this.showNotScrolledError();
        return; // back out of submit if invalid state
      }
      this.btnDisabled = true;
    }

    const submitData = await this.gxProcessing.processFields(this.experience, this.formId, this.selectedPage, this.form.value, this.submissionSvc);
    const fd = await this.viewerSvc.processCalculations(this.experience, submissionType, this.initialData, submitData, this.prefill);

    const metadata = {
      client: platform,
      page: this.selectedPage
    };

    if (this.prefill && Object.keys(this.prefill).length) {
      Object.assign(fd, { _prefill_c4af0d49_e948_4737_8c12_8d64511faeec: this.prefill });
    }

    const payload: FormSubmission = {
      id: this.formId,
      experienceversionid: this.experience.vid,
      submissiontype: submissionType,
      updatedby: '',
      fileid: null,
      data: fd,
      metadata: metadata,
      taskId: taskId,
      lastupdated: this.submission?.lastupdated || null
    };

    // If there is an existing submission then update, else create a new form
    const upsert = this.submission ?
      this.submissionSvc.update(payload) :
      this.submissionSvc.create(payload);

    this.loadingProgress = 0;
    this.loadingMessage = (submissionType === SubmissionType.Submitted)
      ? this.translateSvc.instant("GX_OVERLAY.PROGRESS_SUBMIT")
      : this.translateSvc.instant("GX_OVERLAY.PROGRESS_SAVE");
    this.loading = true;

    upsert.subscribe(result => {
      switch (result.type) {

        case HttpEventType.Sent :
          this.loadingProgress = 0;
          break;

        case HttpEventType.UploadProgress :
        case HttpEventType.DownloadProgress :
          this.loadingProgress = Math.floor(result.loaded / result.total);
          break;

        case HttpEventType.Response :
          this.loadingProgress = 100;
          this.loadingMessage = (submissionType === SubmissionType.Submitted)
              ? this.translateSvc.instant("GX_OVERLAY.DONE_SUBMIT")
              : this.translateSvc.instant("GX_OVERLAY.DONE_SAVE");
          this.submission = result.body; // Store last submission
          this.formId = this.viewerSvc.formId = this.submission.id; // Update form id
          this.form.markAsPristine();

          setTimeout(() => {
            this.loading = false;
            this.loadingMessage = '';
            this.adminSvc.createMetric("CompleteFormViewer",
              {
                "Time": this.time,
                "Experience": this.experience
              });
            this.submitSuccess(submissionType, postMessage);
          }, 2100);
          break;
      }
    }, (err: HttpErrorResponse) => {
      this.loading = false;
      this.loadingProgress = 0;
      this.errorSvc.showErrorMessage(err.message);
      setTimeout(() => {
        this.submitFailure(err);
      }, 2100);
    });
  }

  submitSuccess(submissionType: SubmissionType, postMessage: boolean = true): void {
    if (this.taskId) {
      this.router.navigateByUrl('tasks');
    }
    else {
      switch (submissionType) {
        case SubmissionType.Saved:
          this.toastr.success(
            this.translateSvc.instant('TOASTR_MESSAGE.SAVE_SUCCESS'),
            this.translateSvc.instant('TOASTR_MESSAGE.SAVE_TITLE'));
          break;
        case SubmissionType.Submitted:
          this.toastr.success(
            this.translateSvc.instant('TOASTR_MESSAGE.SUBMIT_SUCCESS'),
            this.translateSvc.instant('TOASTR_MESSAGE.SUBMIT_TITLE'));

          this.btnDisabled = false;
          if (this.experience.sendToSuccessPage) {
            this.router.navigateByUrl(`/viewer/${this.experience.id}/${this.experience.vid}/success`);
          }
          else {
            this.location.go(
              this.router.createUrlTree([], {
                relativeTo: this.activatedRoute,
                queryParams: { formId: this.formId },
                queryParamsHandling: 'merge' }
              ).toString());
          }
          break;
      }
    }
    if (postMessage && window.parent) {
      window.parent.postMessage({
        eventName: (submissionType === SubmissionType.Saved)
          ? WindowMessageEventName.ExperienceSave
          : WindowMessageEventName.ExperienceSubmit,
        formId: this.formId,
        taskId: this.taskId
      }, '*');
    }
    if (postMessage && window.opener) {
      window.opener.postMessage({
        eventName: (submissionType === SubmissionType.Saved)
          ? WindowMessageEventName.ExperienceSave
          : WindowMessageEventName.ExperienceSubmit,
        formId: this.formId,
        taskId: this.taskId
      }, '*');
    }
  }

  submitFailure(err): void {
    console.log('Error: ', err);
    this.btnDisabled = false;
  }

  cancel(): void {
    if (this.taskId) {
      this.router.navigateByUrl('tasks');
    }
  }

  async valueChanged(field: FieldDTO): Promise<void> {
    if (field?.calculations?.onChange) {
      // TODO: Do we still need this timeout in Spring2021+ to avoid the calculations getting old values?
      setTimeout(async () => {
        const updatedData = await this.viewerSvc.getCalculationData(field.calculations.onChange, this.experience, this.form.value, this.prefill);
        if (updatedData) {
          Object.entries(updatedData.fields).forEach(kvp => {
            const key = kvp[0];
            const val = kvp[1];
            this.form.contains(key)
              ? this.form.controls[key].setValue(val)
              : this.initialData[key] = val;
          });
          this.experience = this.gxProcessing.getUpdatedConfig(this.experience, updatedData.configs);
        }
      });
    }
    // If a Photo Element form value changed, have the Viewer submit a form
    if (field.type === ViewerFieldType.PHOTO) {
      this.formId = this.viewerSvc.formId = field['formId'];
      const submitData = await this.gxProcessing.processFields(this.experience, this.formId, this.selectedPage, this.form.value, this.submissionSvc);
      const fd = await this.viewerSvc.processCalculations(this.experience, SubmissionType.Saved, this.initialData, submitData, this.prefill);

      const metadata = {
        client: platform,
        page: this.selectedPage
      };

      if (this.prefill && Object.keys(this.prefill).length) {
        Object.assign(fd, { _prefill_c4af0d49_e948_4737_8c12_8d64511faeec: this.prefill });
      }
      const payload: FormSubmission = {
        id: this.formId,
        experienceversionid: this.experience.vid,
        submissiontype: SubmissionType.Saved,
        updatedby: '',
        fileid: null,
        data: fd,
        metadata: metadata,
        taskId: this.taskId,
        lastupdated: this.submission?.lastupdated || null
      };
      this.submission = (await this.submissionSvc.update(payload).toPromise()) as FormSubmission;
      // END Photo Element fork
    }
    this.viewerSvc.removeViewedPage(this.selectedPage);
  }

  createMetric(next: boolean): void {
    const dir = next ? "Next" : "Back"
    this.adminSvc.createMetric("TimeOnPageGXViewer", { "Page": this.selectedPage,
      "Time": this.time, "Direction": dir, "Experience": this.experience })
  }

  onScroll() {
    if (this.isViewingBottom()) {
      this.scrolledToBottom = true;
      this.viewerSvc.addViewedPage(this.selectedPage);
    }
  }

  isViewingBottom() {
    const element = document.getElementById("mainTop");
    return Math.ceil(element.scrollHeight - element.scrollTop) - element.clientHeight <= 50;
  }

  resetScroll() {
    document.getElementById("mainTop").style.scrollBehavior = 'auto';
    document.getElementById("mainTop").scrollTo(0,0);
    document.getElementById("mainTop").style.scrollBehavior = 'smooth';

    this.scrolledToBottom = false;
  }

  showNotScrolledError() {
    this.errorSvc.showErrorMessage(
      this.translateSvc.instant('WARNING_RIBBON.SCROLL_TO_BOTTOM_MSG')
    );
  }

  validateRequiredFields() {
    this.needsValidation = true;
  }

  // listen for refresh events, from the designer
  @HostListener('window:message', ['$event']) onPostMessage(event) {
    if (event.data === WindowMessageEventName.RefreshPreviewWindow) {
      location.reload();
    }
  }
}
