import { Component, OnInit, EventEmitter, Output, ViewChild, OnDestroy, HostListener, TemplateRef, ComponentFactoryResolver, AfterViewInit, ComponentRef, ChangeDetectorRef, NgZone } from '@angular/core';
import { StateViewerService } from '../state/state-viewer.service';
import { FormSubmissionService } from '../services/form-submission.service';
import {
  WindowMessageEventName,
  TaskDTO,
  TaskStatus,
  SubmissionType
} from '@next/shared/common';
import { NextAdminService } from '@next/shared/next-services';
import { ViewerPdfComponent } from '@next/gx-viewer/feature-shell';
import { Subscription, timer } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GenerateComponentDirective, ConfirmationDialogComponent } from '@next/shared/ui';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SignaturePromptComponent } from '../signature-prompt/signature-prompt.component';

@Component({
  selector: 'next-pdf-form-viewer',
  templateUrl: './pdf-form-viewer.component.html',
  styleUrls: ['./pdf-form-viewer.component.scss']
})
export class PdfFormViewerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(GenerateComponentDirective) generateComponent: GenerateComponentDirective;
  @ViewChild('viewModePrompt', {static: true}) private viewPrompt: TemplateRef<any>;
  signatureProperties: any;

  modalRef: BsModalRef;
  @Output() renderedPDF: EventEmitter<any> = new EventEmitter<any>()

  viewerPdf: ComponentRef<ViewerPdfComponent>;

  selectedForms: TaskDTO[];
  currentFormIndex = 0;

  eventBus: any;
  viewerApp: any;

  currentPage: any;
  totalPages: any;
  loading = true;

  lastForm = false;
  finishedForms = false;
  batchedFormsPrompt = false;


  componentSub: Subscription = new Subscription();

  time = 0;
  isDirtyRead:boolean=false;


  viewModeModal: BsModalRef;

  constructor (
    private cd: ChangeDetectorRef,
    public stateViewerSvc: StateViewerService,
    private modalSvc: BsModalService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private zone: NgZone,
    private translateSvc: TranslateService,
    private route: ActivatedRoute,
    private adminSvc: NextAdminService,
    private formSubmitSvc: FormSubmissionService,
    private toastrSvc: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {
    if (this.route.snapshot.data.batchedFormsPrompt) {
      this.batchedFormsPrompt = true;
    }
    this.componentSub.add(this.stateViewerSvc.selectedTasks$.subscribe(forms => {
      this.finishedForms = false;
      this.selectedForms = forms;
      this.lastForm = this.checkRemainingForms().length <= 1;
    }));

    this.componentSub.add(this.stateViewerSvc.formIndex$.subscribe(val => {
      this.currentFormIndex = val;

      this.adminSvc.createMetric("StartFormPDF", this.selectedForms[this.currentFormIndex]);
    }));

    timer(0, 1000).subscribe(() => {
      this.time++;
  });
  }

  ngAfterViewInit() {
    const pdfCmpFactory = this.componentFactoryResolver.resolveComponentFactory(
      ViewerPdfComponent
    );

    const apptViewContainerRef = this.generateComponent.viewContainerRef;
    apptViewContainerRef.clear();

    this.viewerPdf = apptViewContainerRef.createComponent(pdfCmpFactory);

    this.viewerPdf.instance.isEmbedded = true;
    this.viewerPdf.instance.isPatientView.next(this.stateViewerSvc.isPatientView.value);
    this.stateViewerSvc.isPatientView.subscribe((result) => {
      this.viewerPdf.instance.isPatientView.next(result);
    });
    this.viewerPdf.instance.pdfViewerEmitter.subscribe(evt => { 
      this.pdfViewerEvent(evt);
    });

    this.cd.detectChanges();
  }

  async ngOnDestroy() {
    if (!this.stateViewerSvc.viewMode) {
      await this.formSubmitSvc.saveForm(false);
    }
    this.componentSub.unsubscribe();
    this.eventBus = null;
    this.stateViewerSvc.viewMode = false;
  }

  createMetric(next: boolean) {
    const dir = next ? "Next" : "Back"
    this.adminSvc.createMetric("TimeOnPagePDFViewer", { "Page": this.viewerApp.page,
    "Time": this.time, "Direction": dir, "Experience": this.selectedForms[this.currentFormIndex] })
    this.time = 0;
  }

  previousPage() {
    this.createMetric(false);
    const currentPage = this.viewerApp.page;
    if (this.eventBus && currentPage !== 1) {
      this.eventBus.dispatch('previouspage');
    }
  }

  nextPage() {
    this.createMetric(true);
    const currentPage = this.viewerApp.page;
    if (this.eventBus && currentPage !== this.totalPages) {
      this.eventBus.dispatch('nextpage');
    }
  }

  signaturePrompt() {
    const activeTask = this.selectedForms[this.currentFormIndex];
    const signaturePromptModal = this.modalSvc.show(SignaturePromptComponent, {
      class: this.batchedFormsPrompt ? 'modal-dialog-centered' : 'modal-iop-signature-prompt',
      backdrop: true,
      keyboard: false,
      ignoreBackdropClick: true,
      initialState: {
        task: activeTask,
        batchSign: this.batchedFormsPrompt,
        signatureProperties: this.signatureProperties
      }
    });
    const submitSub: Subscription = signaturePromptModal.content.staffSignature.subscribe(val => {
      this.staffSignature(val);
    });
    const cancelSub: Subscription = signaturePromptModal.content.exitModal.subscribe(() => {
      submitSub.unsubscribe();
      cancelSub.unsubscribe();
      signaturePromptModal.hide();
    });
  }

  pdfViewerRendered(viewerApp) {
    this.loading = true;
    this.viewerApp = viewerApp;
    this.eventBus = viewerApp.eventBus;
    this.totalPages = this.viewerApp.pagesCount;
    this.currentPage = this.viewerApp.page;
    this.loading = false;
    this.eventBus?.on('pagechanging', (e) => {
      this.zone.run(() => {
        this.currentPage = e.pageNumber;
      });
    });
  }

  viewModePrompt() {
    const config: any = {
      class: 'modal-dialog-centered',
      backdrop: true,
      keyboard: false,
      ignoreBackdropClick: true,
    };
    this.viewModeModal = this.modalSvc.show(this.viewPrompt, config);
  }

  exitViewModeModal() {
    this.viewModeModal.hide();
  }

  pdfViewerEvent(event) {
    switch(event.eventName) {
      case WindowMessageEventName.SubmitError: {
        this.formSubmitSvc.updateErrorPrompt(this.selectedForms[this.currentFormIndex].id);
        break;
      }
      case WindowMessageEventName.RenderedPDF: {
        this.pdfViewerRendered(event.viewerApp);
        break;
      }
      case WindowMessageEventName.ExperienceSubmit: {
        if (!this.stateViewerSvc.viewMode) {
          this.formSubmitSvc.latestTask(event.taskId);
        }
        break;
      }
      case WindowMessageEventName.Signature: {
        if (this.stateViewerSvc.viewMode) {
          this.viewModePrompt()
          break;
        }
        this.signatureProperties = event.signatureProperties;
        this.signaturePrompt();
        break;
      }
      case WindowMessageEventName.FieldChanged: {
        this.isDirtyRead=true;
        if (this.stateViewerSvc.viewMode) {
          this.viewModePrompt()
          break;
        }
        delete event.eventName;
        event.currentTask = this.selectedForms[this.currentFormIndex];
        this.stateViewerSvc.renderedForm = event;
        break;
      }
      default:
        break;
    }
  }

  checkRemainingForms() {
    return this.selectedForms.filter(obj => obj.status.toLowerCase() !== TaskStatus.Complete);
  }

  nextForm() {
      this.adminSvc.createMetric("CompleteFormPDF", {"Time": this.time, "Experience": this.selectedForms[this.currentFormIndex]});
      this.viewerPdf.instance.submit(SubmissionType.Submitted);
  }

  staffSignature(event) {
    this.viewerPdf.instance.onStaffSignature(event)
  }

  checkForSignatures() {
    return !!Object.values(this.viewerPdf.instance.formData).filter((value: any) => (value.Type === 'TypedSignature' || value.Type === 'Signature') && value.Value.SignedDate).length;
  }

  clearSignatures() {
    //check first to see if there are any signatures on the form
    if (this.checkForSignatures()) {
      const config  = {
        initialState: {
          title: this.translateSvc.get('CONFIRMATION_DEL.TITLE'),
          message: this.translateSvc.get('CONFIRMATION_DEL.TASK'),
          cancelButton: this.translateSvc.get('CONFIRMATION_DEL.CANCEL'),
          confirmButton: this.translateSvc.get('CONFIRMATION_DEL.CONFIRM')
        },
        class: 'modal-confirmation',
        ignoreBackdropClick: true,
        keyboard: false
      };
      this.modalRef = this.modalSvc.show(ConfirmationDialogComponent, config);
      this.modalRef.content.onClose.subscribe(result => {
        if (result) {
          this.eventBus.dispatch('clear-signatures');
          this.viewerPdf.instance.submit(SubmissionType.Saved);
        }
      }, (err) => { this.toastrSvc.error(err.message) });
    }
  }

  isSaveDisabled() {
    if (this.stateViewerSvc.viewMode) return true;
    if (this.currentPage !== this.totalPages) return true;
    return !(this.viewerPdf?.instance?.isFormValidForCurrentView);
  }

  getFinishButtonLabel() {
    if (!this.viewerPdf) {
      return '';
    }

    // patient view
    if (this.viewerPdf.instance.isPatientView.getValue()) {
      if (this.lastForm) {
        return this.translateSvc.instant('FORM_VIEWER.FOOTER.FINISH');
      }
      else {
        return this.translateSvc.instant('FORM_VIEWER.FOOTER.FINISH_NEXT');
      }
    }
    else {
      // staff view
      if (this.lastForm && this.viewerPdf.instance.isFormValidForAll) {
        // last form, all required fields for whole form satisfied
        return this.translateSvc.instant('FORM_VIEWER.FOOTER.FINISH_CLOSE');
      }

      if (!this.lastForm && this.viewerPdf.instance.isFormValidForAll) {
        // not the last form, all required fields for whole form satisfied
        return this.translateSvc.instant('FORM_VIEWER.FOOTER.FINISH_NEXT');
      }

      if (!this.viewerPdf.instance.isFormValidForAll && this.lastForm) {
        // last form, all required fields for other people satisfied except the current user
        return this.translateSvc.instant('FORM_VIEWER.FOOTER.SAVE_CLOSE');
      }

      if (!this.viewerPdf.instance.isFormValidForAll && !this.lastForm) {
        // not last form, all required fields for other people not satisfied
        return this.translateSvc.instant('FORM_VIEWER.FOOTER.SAVE_NEXT');
      }
    }
    return this.translateSvc.instant('FORM_VIEWER.FOOTER.FINISH');
  }

  closeViewer()
  {
    if(this.isDirtyRead)
    {
      const config  = {
        initialState: {
          title: this.translateSvc.get('CONFIRMATION_FORM_VIEWER.TITLE'),
          message: this.translateSvc.get('CONFIRMATION_FORM_VIEWER.MESSAGE'),
          cancelButton: this.translateSvc.get('CONFIRMATION_FORM_VIEWER.CANCEL'),
          confirmButton: this.translateSvc.get('CONFIRMATION_FORM_VIEWER.CONFIRM')
        },
        class: 'modal-confirmation',
        ignoreBackdropClick: true,
        keyboard: false
      };
      this.modalRef = this.modalSvc.show(ConfirmationDialogComponent, config);
      this.modalRef.content.onClose.subscribe(result =>  {
        if (result) {
          this.router.navigate(['form/viewer'],{ queryParams: { form: false } });
        }
      }, (err) => { this.toastrSvc.error(err.message) });
      

    }
    else{
      this.router.navigate(['form/viewer'],{ queryParams: { form: false } });
    }
    
  }

  get pages() {
    return { current: this.currentPage, total: this.totalPages}
  }

  @HostListener('window:beforeunload') async saveState() {
    if (!this.stateViewerSvc.viewMode) {
      await this.formSubmitSvc.saveForm(false);
    }
  }
}
