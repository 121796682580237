<div class="form-group" [ngClass]="{'d-none' : field.hidden}" [formGroup]="valueFormGroup">
  <label for="{{ field.name }}" class="m-0 field_text">
    {{ field.text }}
    <span *ngIf="field.required" class="required">*</span>
  </label>

    <ng-select
      #dd
      [searchable]="field.dropDownFilter || field.dropDownAllowText"
      [closeOnSelect]="!field.dropDownMultiSelect"
      [clearable]="!field.dropDownMultiSelect"
      id="{{ field.name }}"
      formControlName="Text"
      [ngClass]="{'invalid' : needsValidation && valueFormGroup?.get('Text').errors?.required}"
      (change)="onChange($event)"
      (search)="onSearch($event)"
      (blur)="onBlur()"
      [placeholder]="field.dropDownMultiSelect ? label : ''">
        <ng-container *ngIf="!field.dropDownMultiSelect">
          <ng-option *ngFor="let option of field.options" [disabled]="field.required && option.value===''" [value]="option.value">{{ option.text }}</ng-option>
        </ng-container>
        <ng-container *ngIf="field.dropDownMultiSelect">
          <ng-template ng-label-tmp>{{label}}</ng-template>
          <ng-template ng-header-tmp class="header">
            Select Items<button class="btn btn-primary float-right p-0 pl-1 pr-1" (click)="onDone()">Done</button>
          </ng-template>
          <ng-option  *ngFor="let option of field.options" [value]="option.value">
            <input type="checkbox" [value]="option.value" [attr.checked]="getCheckedStatus(option.value)" />
            <span class='d-none'>{{getCheckedStatus(option.value)}}</span>
            {{ option.text  }}</ng-option>
        </ng-container>

    </ng-select>

    <!-- Validation Error Message -->
    <span *ngIf="needsValidation" class="float-right text-sizing color-danger">
      <span *ngIf="valueFormGroup?.get('Text').errors?.required">{{ 'REQUIRED_FIELD' | translate }}</span>
    </span>

    <div *ngFor="let option of field.options; let lastItem=last">
      <div *ngIf="getFieldValue(field) === option.value" [@easeIn]="animateState">
        <br>
        <div *ngFor="let subField of getSwitchFields(option.value)">
          <next-viewer-element [form]="form" [field]="subField" [attachments]="attachments" (valueChanged)="emitValueChanged($event)"
            [initialState]="initialState" [needsValidation]="needsValidation"></next-viewer-element>
        </div>
      </div>
      <span *ngIf="lastItem">{{turnAnimationsOn()}} </span>
    </div>
  </div>
